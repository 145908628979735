import React, { useState } from "react";
import { useTable, useGlobalFilter, useAsyncDebounce } from "react-table";

import { UserPlusIcon, UserMinusIcon } from "@heroicons/react/24/solid";
import { useEventEnrollmentMutation } from "../../redux/features/api/apiSlice";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  rows,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="flex flex-row items-center justify-between gap-2 p-1">
      <span className="p-2 text-sm ">Search:</span>
      <input
        className="flex-1 w-full p-2 text-sm focus:outline-none"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
      />
    </div>
  );
}
const globalFilterFunction = (rows, id, filterValue) => {
  return rows.filter((row) => {
    return Object.values(row.original).some((value) =>
      String(value).toLowerCase().includes(String(filterValue).toLowerCase())
    );
  });
};

const TableList = ({ columns, data }) => {
  let { eventId, tournamentId } = useParams();
  const [enrollPlayer] = useEventEnrollmentMutation();
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState('');

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { globalFilter },
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: {},
      globalFilter: globalFilterFunction,
    },
    useGlobalFilter
  );

  const handleplayerEnrollment = async (data) => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await enrollPlayer({
        eventId,
        data: { playerId: data.playerId, enroll: true, tournamentId },
      });
      console.log(res)
      if (res?.data?.error === false) {
        toast.success("Player Added");
      }
      if (res?.error?.data?.error !== undefined) {
        toast.error(res?.error?.data?.error );
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
      setIndex('')
    }
  };

  return (
    <table {...getTableProps()} className="min-w-full divide-y divide-gray-300">
      <thead>
        {headerGroups.map((headerGroup, i) => (
          <tr key={i} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, i) => (
              <div key={i}>
                {/* <th>
                  <input type="checkbox" onChange={handleSelectAll} />
                </th> */}
                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap uppercase">
                  {column.render("Header")}
                </th>
                <th className="text-sm font-semibold uppercase whitespace-nowrap">
                  {/* <button className="">Assign Event</button> */}
                  Status
                </th>
              </div>
            ))}
          </tr>
        ))}

        <tr>
          <th colSpan={visibleColumns.length + 1} className="border">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </th>
        </tr>
      </thead>
      <tbody {...getTableBodyProps()} className="px-3 border-l border-r">
        {rows.map((row, idx) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              key={idx}
              className={`${idx % 2 === 0 ? undefined : "bg-gray-200"}  px-4`}
            >
              {row.cells.map((cell, i) => {
                // const isChecked = () => {
                //   return selectedPlayers.includes(row.original.id);
                // };

                return (
                  <>
                    <td
                      {...cell.getCellProps()}
                      key={i}
                      className="px-3 py-4 text-sm text-gray-500"
                    >
                      {cell.render("Cell")}
                    </td>
                    <td
                      className={`${
                        row.original.status === "assigned"
                          ? "bg-rose-500"
                          : "bg-teal-500"
                      } border-y`}
                    >
                      {row.original.status === "assigned" ? (
                        <button
                          className="flex flex-col items-center justify-center w-full"
                          onClick={() =>
                            handleplayerEnrollment({
                              playerId: row.original.id,
                              enroll: false,
                            })
                          }
                        >
                          <UserMinusIcon className="w-6 h-6 text-gray-100" />
                        </button>
                      ) : (
                        <button
                          className="flex flex-col items-center justify-center w-full"
                          onClick={() =>
                            {handleplayerEnrollment({
                              playerId: row.original.id,
                            }); setIndex(i)}
                          }
                        >
                          {(loading && index === i) ? (
                            <div className="flex items-center justify-center">
                              <svg
                                className="animate-spin h-5 w-5 text-gray-100"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                ></path>
                              </svg>
                            </div>
                          ) : (
                            <UserPlusIcon className="w-6 h-6 text-gray-100" />
                          )}
                        </button>
                      )}
                    </td>
                  </>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TableList;
