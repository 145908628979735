import React, { useEffect, useState } from "react";
import SlidePanel from "../../../components/commons/slidePanel";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DrawsTab from "../../../components/sections/tabs/DrawsTab";
import MatchesTab from "../../../components/sections/tabs/MatchesTab";
import { classNames } from "../../../utils/classNames";
import { useSelector } from "react-redux";
import ManagePlayers from "../../../components/sections/mangePlayers/ManagePlayers";
import {
  useGetEventByIdQuery,
  useGetEventEntriesQuery,
  useGetTournamentByIdQuery,
} from "../../../redux/features/api/apiSlice";
import AddEventForm from "./AddEventForm";
import { SquaresPlusIcon } from "@heroicons/react/24/solid";
import loading from "../../../images/loading-image.svg";
import AdminPlayersTab from "../../../components/sections/tabs/AdminPlayersTab";
import AddDrawForm from "./AddDrawForm";

const sidePanelConfigs = {
  players: {
    title: "Manage Players",
    description: "Following Players are Eligible for the Event.",
    component: (setOpen) => <ManagePlayers />,
  },
};

const EventPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [drawForm, setDrawForm] = useState(false);
  let { eventId, tournamentId } = useParams();
  const { refetch: refetchTournament } =
    useGetTournamentByIdQuery(tournamentId);
  const { isFetching, refetch: refetchEvent } = useGetEventByIdQuery(eventId);
  const { event } = useSelector((state) => state.events);
  const { tournament } = useSelector((state) => state.tournaments);
  const { refetch: refetchEntries } = useGetEventEntriesQuery(eventId);
  const { eventEntries } = useSelector((state) => state.entries);

  const tabs = [
    {
      name: "draws",
      href: "#draws",
      element: () => <DrawsTab event={event} tournament={tournament} />,
    },
    { name: "matches", href: "#matches", element: () => <MatchesTab /> },
    {
      name: "players",
      href: "#players",
      element: () => (
        <AdminPlayersTab entries={eventEntries} removePlayer={true} />
      ),
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  const handleSidePanelOpener = () => {
    setOpen(!open);
  };

  const handleActiveTabChange = (tab) => {
    setActiveTab(tab.name);
    navigate(tab.href);
  };

  const handleDrawForm = () => {
    setDrawForm(false);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  useEffect(() => {
    if (!location.hash) {
      setActiveTab(tabs[0].name);
    } else {
      const currentTab = tabs.find((tab) => tab.href === location.hash);
      if (currentTab) {
        setActiveTab(currentTab.name);
      }
    }
  }, [location.hash]);

  useEffect(() => {
    refetchTournament();
    refetchEntries();
    refetchEvent();
  }, [eventId]);

  if (isFetching)
    return (
      <div className=" h-screen w-full flex justify-center items-center">
        <img className="-mt-32" src={loading} alt="" />
      </div>
    );

  return (
    <>
      {Object.keys(sidePanelConfigs).includes(activeTab) && (
        <SlidePanel
          config={{ open, setOpen }}
          section={sidePanelConfigs[activeTab]}
          eventId={eventId}
        />
      )}
      {drawForm ? (
        <AddDrawForm handleCloseForm={handleDrawForm} event={event} tournament={tournament}/>
      ) : showForm ? (
        <AddEventForm handleCloseForm={handleCloseForm} event={event} />
      ) : (
        <>
          <div>
            <div className=" px-3 mt-5">
              <div className="flex items-center gap-3">
                <div className="text-lg font-bold">
                  {tournament?.tournamentName}
                </div>
                {tournament?.status === "published" ? (
                  <div className="flex items-center font-medium uppercase text-[10px] px-2 h-5 bg-blue-600 text-white rounded-sm">
                    {tournament?.status}
                  </div>
                ) : (
                  <div className="flex items-center font-medium uppercase text-[10px] px-2 h-5 bg-gray-400 text-white rounded-sm">
                    {tournament?.status}
                  </div>
                )}
              </div>
              <div className="flex items-center gap-1 mt-2">
                <div className="text-xs font-medium text-gray-500">
                  {tournament?.startDate?.split("T")[0]} -{" "}
                </div>
                <div className="text-xs font-medium text-gray-500">
                  {" "}
                  {tournament?.endDate?.split("T")[0]}
                </div>
              </div>
              <div className="flex items-center gap-1 mt-1">
                <div className="text-xs font-medium text-gray-500">
                  {tournament?.location}
                </div>
              </div>
            </div>
            <div className="flex items-start gap-2 mt-5 mb-10">
              <button onClick={() => setShowForm(true)} className="mt-1">
                <SquaresPlusIcon height={20} width={20} />
              </button>

              <div className=" ">
                <div className="flex items-center gap-3">
                  <div className="text-lg font-bold">{event?.eventName}</div>
                  <div
                    className={`flex items-center font-medium uppercase text-[10px] px-2 h-5 ${
                      event.entry ? "bg-blue-600" : "bg-gray-400"
                    }  text-white rounded-sm`}
                  >
                    {event.entry ? "OPEN" : "CLOSED"}
                  </div>
                  <div
                    className={`flex items-center font-medium uppercase text-[10px] px-2 h-5 ${
                      event.status === "published"
                        ? "bg-blue-600"
                        : "bg-gray-400"
                    } text-white rounded-sm`}
                  >
                    {event.status}
                  </div>
                </div>
                <div className="flex items-center gap-1 mt-2">
                  <div className="text-xs font-medium text-gray-500">
                    {event?.startDate?.split("T")[0]} -{" "}
                  </div>
                  <div className="text-xs font-medium text-gray-500">
                    {" "}
                    {event.startTime}
                  </div>
                </div>
                <div className="flex items-center gap-1 mt-1">
                  <div className="text-xs font-medium text-gray-500">
                    Event Fee: ${event.eventFee}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="relative pb-5 border-b border-gray-200 sm:pb-0 px-3 ">
            <div className="md:flex md:items-center md:justify-between">
              <div className="flex mt-3 md:absolute md:right-0 md:top-3 md:mt-0">
                {activeTab === "draws" && !event?.draw?.id && (
                  <button
                    onClick={() => setDrawForm(true)}
                    className="inline-flex items-center px-3 py-2 text-xs sm:text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    New Draw
                  </button>
                )}
                {activeTab === "players" && (
                  <div className="flex gap-3">
                    {/* <SearchPlayers eventId={eventId} onEnrollmentComplete={() => setReload(true)} /> */}
                    <button
                      onClick={handleSidePanelOpener}
                      className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Manage Players
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-8">
              <div className="">
                <nav className="flex -mb-px space-x-4 sm:space-x-8">
                  {tabs.map((tab) => (
                    <button
                      onClick={() => handleActiveTabChange(tab)}
                      key={tab.name}
                      className={classNames(
                        tab.name === activeTab
                          ? "border-indigo-500 text-indigo-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 px-1 pb-4 text-xs sm:text-sm font-medium capitalize"
                      )}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <div>
            {tabs.map((tab, i) => {
              if (tab.name === activeTab) {
                return (
                  <div className="px-3" key={i}>
                    {tab.element()}
                  </div>
                );
              }
              return null; // Ensure a valid return for other cases
            })}
          </div>
        </>
      )}
    </>
  );
};

export default EventPage;
