import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useDrawSeedingMutation,
  useFinalDrawMutation,
  useGetEventDrawQuery,
  useResetDrawMutation,
} from "../../../redux/features/api/apiSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import GroupCard from "../../card/Group";

const UserDrawsTab = ({ event, tournament }) => {
  let { eventId } = useParams();
  const [finalDraw] = useFinalDrawMutation();
  const [drawSeeding] = useDrawSeedingMutation();
  const [resetDraw] = useResetDrawMutation();
  const { isFetching, refetch } = useGetEventDrawQuery(eventId);
  const { draw } = useSelector((state) => state.draws);
  const [deleteDraw, setDelteDraw] = useState(false);
  const [loading, setLoading] = useState(false);
  const [seeding, setSeeding] = useState(true);
  const [player1, setPlayer1] = useState("");
  const [hoveredPlayer, setHoveredPlayer] = useState(null);
  const [hoveredGroup, setHoveredGroup] = useState(null);

  useEffect(() => {
    refetch();
  }, [eventId]);

  if (isFetching)
    return (
      <div className=" h-screen w-full flex justify-center items-center">
        <img className="-mt-32" src={loading} alt="" />
      </div>
    );

  return (
    <>
      <div className="flex flex-col items-start justify-start w-full mt-8">
        {draw?.id && draw?.finalised ? (
          <div className="w-full">
            <div className="flex justify-between">
              <div className="flex items-start gap-2 mt-5 mb-10">
                <div className=" ">
                  <div className="flex items-center gap-3">
                    <div className="text-lg font-bold">{draw?.drawName}</div>
                  </div>
                  <div className="flex items-center gap-1 mt-2">
                    <div className="text-xs font-medium text-gray-600">
                      Estemated Start: {draw?.startDate?.split("T")[0]},{" "}
                    </div>
                    <div className="text-xs font-medium text-gray-600">
                      {" "}
                      {draw?.startTime}
                    </div>
                  </div>
                  <div className="flex items-center gap-1 mt-1">
                    <div className="text-xs font-medium text-gray-600">
                      Format:{" "}
                      {draw?.drawFormat === "RR"
                        ? "Round-Robin"
                        : "Single Elimination"}
                    </div>
                  </div>
                  <div className="flex items-center gap-1 mt-1">
                    <div className="text-xs font-medium text-gray-600">
                      First to {draw?.defaultMatchFormat?.pointsPerGame} (Win by{" "}
                      {draw?.defaultMatchFormat?.deuce ? "2" : "1"} ){" "}
                      <span className="text-sm font-black">.</span> Best of{" "}
                      {draw?.defaultMatchFormat?.gamesPerMatch}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full grid grid-cols-2 gap-10">
              {draw?.groups &&
                draw?.groups?.length &&
                draw.groups
                  .slice() // Create a shallow copy of the array
                  .sort((a, b) => a.number - b.number) // Sort the copied array
                  .map((group, i) => <GroupCard group={group} key={i} />)}
            </div>
          </div>
        ):
        <div className="w-full text-center text-gray-500">No Available Draws</div>}
      </div>
    </>
  );
};

export default UserDrawsTab;
