import React, { useState } from "react";
import { useDrawSeedingMutation } from "../../../redux/features/api/apiSlice";
import { toast } from "react-toastify";

const RoundRobin = ({draw, seeding}) => {
  const [drawSeeding] = useDrawSeedingMutation();
  const [player1, setPlayer1] = useState("");
  const [hoveredPlayer, setHoveredPlayer] = useState(null);
  const [hoveredGroup, setHoveredGroup] = useState(null);

  const handleSeeding = async (data, order) => {
    try {
      if (player1?.groupId === data?.groupId) {
        setPlayer1(data);
        return;
      }
      if (player1 === "") {
        setPlayer1(data);
      } else {
        if (
          seeding &&
          player1?.order !== data?.order
        ) {
          setPlayer1(data);
          return;
        }

        const res = await drawSeeding({
          id: draw.id,
          data: { player1, data, seeding, order, format:'RR' },
        });
        if (res?.data?.error === false) {
          toast.success(res.data.data);
          setPlayer1("");
        }
        if (res?.error?.data?.error !== undefined) {
          toast.success(res.error.data.error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="w-full grid grid-cols-4 gap-3">
        {draw?.groups &&
          draw?.groups?.length &&
          // Create a copy of the array before sorting
          draw.groups
            .slice()
            .sort((a, b) => a.number - b.number)
            .map((group, i) => (
              <div className="" key={i}>
                <div className="font-semibold text-lg px-3 py-1">
                  Group {group.number +1}
                </div>
                <div
                  onMouseEnter={() => setHoveredGroup(group.id)}
                  onMouseLeave={() => setHoveredGroup(null)}
                  className={`px-3 py-3 space-y-2 rounded-lg ${
                    !seeding && player1?.id && hoveredGroup === group?.id
                      ? "bg-green-200"
                      : ""
                  }`}
                >
                  {group?.groupPlayers?.length > 1 &&
                    [...group.groupPlayers]
                      .sort((a, b) => a.order - b.order)
                      .map((player, i) => (
                        <div
                          key={i}
                          onClick={() => handleSeeding(player)}
                          onMouseEnter={() => setHoveredPlayer(player)} // Track hovered player
                          onMouseLeave={() => setHoveredPlayer(null)}
                          className={`flex items-center gap-2 border shadow rounded-md px-3 py-1 cursor-pointer ${
                            player1?.id === player?.id ? `bg-gray-300` : ""
                          }
                                    ${
                                      seeding
                                        ? hoveredPlayer &&
                                          hoveredPlayer?.order ===
                                            player1?.order
                                          ? "hover:bg-green-400"
                                          : "hover:bg-red-400"
                                        : "hover:bg-green-400"
                                    }
                                    `}
                        >
                          <div className="h-5 w-5 rounded-full flex justify-center items-center bg-blue-300 text-white font-medium text-xs">
                            {player.order +1}
                          </div>

                          <div className="border bg-white w-full px-2 rounded">
                            <div className="flex gap-1 text-[11px] text-gray-500">
                              <div>{player?.player?.rating}</div>
                              <span className="font-black text-black text-xs">
                                .
                              </span>
                              <div>{player?.player?.homeAddress}</div>
                            </div>
                            <div className="text-sm font-medium text-gray-700">
                              {player?.player?.firstName},{" "}
                              {player?.player?.lastName}
                            </div>
                          </div>
                        </div>
                      ))}
                  {!seeding &&
                    player1?.id &&
                    hoveredGroup === group?.id &&
                    player1?.groupId !== group.id && (
                      <button
                        onClick={() =>
                          handleSeeding(
                            group.id,
                            group?.groupPlayers?.length + 1
                          )
                        }
                        className="bg-green-400 py-2 rounded-lg text-center text-sm w-full hover:border hover:shadow hover:bg-green-500"
                      >
                        + Add Here
                      </button>
                    )}
                </div>
              </div>
            ))}
      </div>
    </>
  );
};

export default RoundRobin;
