import { configureStore } from "@reduxjs/toolkit/react";
import userReducer from "./features/userSlice";
import tournamnetReducer from "./features/tournamentSlice";
import eventReducer from "./features/eventSlice";
import teamReducer from "./features/teamSlice";
import playerReducer from "./features/playerSlice";
import tableReducer from "./features/tableSlice";
import { apiSlice } from "./features/api/apiSlice";
import entryReducer from "./features/entrySlice";
import drawReducer from "./features/drawSlice";
import matchReducer from "./features/matchSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    tournaments: tournamnetReducer,
    events: eventReducer,
    teams: teamReducer,
    players: playerReducer,
    tables: tableReducer,
    entries: entryReducer,
    draws: drawReducer,
    matches: matchReducer,

    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});
