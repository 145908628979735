import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";


export const initialState = {
  match:{},
  matches: [],
};

export const matchSlice = createSlice({
  name: "match",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getGroupMatches.matchFulfilled,
      (state, action) => {
        state.matches = action.payload.data;
      }
    );
  },
});

export default matchSlice.reducer;
