import React, { useEffect, useState } from "react";
import { useGetQueryTournamentsQuery } from "../../../redux/features/api/apiSlice";
import { useSelector } from "react-redux";
import {
  Squares2X2Icon,
  Bars2Icon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import { TournamentCard } from "../../../components";
import loading from "../../../images/loading-image.svg";
import { Link } from "react-router-dom";

const TournamentsPage = () => {
  const [activeTab, setActiveTab] = useState("active");
  const [search, setSearch] = useState("");
  let status = "published";
  const { isFetching, refetch } = useGetQueryTournamentsQuery(
    new URLSearchParams({
      activeTab: activeTab,
      search: search,
      status: status,
    }).toString()
  );

  const { queryTournaments } = useSelector((state) => state.tournaments);
  const [dataView, setDataView] = useState("grid"); //! grid | tabel

  const handleTabChange = () => {
    setActiveTab(activeTab === "active" ? "past" : "active");
  };

  useEffect(() => {
    refetch();
  }, [activeTab, search]);

  return (
    <>
      <div className="container mx-auto px-3 ">
        <div className="flex flex-row items-center justify-between py-6">
          <div className="flex flex-row items-center justify-start py-4 ">
            <div className="divide-x-2 text-[10px] sm:text-sm">
              <button
                onClick={handleTabChange}
                className={`px-2 sm:px-4 py-2  uppercase rounded-s-md ${
                  activeTab === "active"
                    ? "bg-black text-white font-medium"
                    : "bg-white text-black"
                }`}
              >
                active
              </button>
              <button
                onClick={handleTabChange}
                className={`px-2 sm:px-4 py-2  uppercase rounded-e-md ${
                  activeTab === "past"
                    ? "bg-black text-white font-medium"
                    : "bg-white text-black"
                }`}
              >
                past
              </button>
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-2 py-4">
            <div className="flex flex-row items-center justify-start px-1 sm:px-4 py-1 sm:py-2 bg-white border rounded-md ">
              <MagnifyingGlassIcon className="h-4 w-4 sm:w-5 sm:h-5" />
              <input
                  type="search"
                  onChange={(e)=>setSearch(e.target.value)}
                  className="px-1 bg-transparent border-none outline-none focus:outline-none"
                />
            </div>
            <div className="hidden sm:block">
              <button
                onClick={() =>
                  setDataView(dataView === "grid" ? "tabel" : "grid")
                }
                className={`px-2 sm:px-4 py-1 sm:py-2  uppercase rounded-s-md ${
                  dataView === "grid"
                    ? "bg-black text-white font-medium"
                    : "bg-white text-black"
                }`}
              >
                <Squares2X2Icon className="h-3 w-3 sm:w-5 sm:h-5" />
              </button>
              <button
                onClick={() =>
                  setDataView(dataView === "tabel" ? "grid" : "tabel")
                }
                className={`px-2 sm:px-4 py-1 sm:py-2  uppercase rounded-e-md ${
                  dataView === "tabel"
                    ? "bg-black text-white font-medium"
                    : "bg-white text-black"
                }`}
              >
                <Bars2Icon className="h-3 w-3 sm:w-5 sm:h-5 " />
              </button>
            </div>
          </div>
        </div>
        {isFetching ? (
          <div className=" h-[500px] w-full flex justify-center items-center">
            <img className="-mt-32" src={loading} alt="" />
          </div>
        ) : queryTournaments?.length < 1 ? (
          <div className="text-center mt-20 font-medium text-gray-500">
            No Tournaments Available
          </div>
        ) : (
          <div
            className={`grid gap-4  ${
              activeTab === "grid" ? "grid-cols-4" : "grid-cols-12"
            }`}
          >
            {queryTournaments?.map((tournament, index) => {
              return (
                <div
                  key={index}
                  className={`p-6 bg-white border rounded-md shadow-sm ${
                    dataView === "grid"
                      ? "col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3"
                      : "col-span-12"
                  }`}
                >
                  <TournamentCard tournament={tournament}/>
                </div>
              );
            })}
          </div>
        )}
        <div className="absolute bottom-0 left-[45%] mb-10 flex justify-center items-center gap-2">
          <Link to='/display' className="text-sm font-medium">Display</Link>
          <span>|</span>
          <Link to='/matchreporter' className="text-sm font-medium">Match Reporter</Link>
        </div>
      </div>
    </>
  );
};

export default TournamentsPage;
