import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";

export const drawsInitialState = {
  drawName: "",
  eventId: "",
  drawId: "",
  startDate: "",
  drawFormat: "RR",
  startTime: "",
  scheduleMatches: false,
  defaultMatchFormat: {
    pointsPerGame: 11,
    gamesPerMatch: 5,
    deuce: true,
    playAllGames: false,
  },
  roundRobinSettings: {
    idealGroupSize: 4,
    groupPreference: "small", // larger | smaller
    noAdvance: 2,
    seeding: "snake", // snake | divisions
    useExactNoOfGroups: false,
    unratedPlayersToAdvance: false,
    ladderFormat: false,
    upDown: 1
  },
};

export const initialState = {
  draw: {},
};

export const drawSlice = createSlice({
  name: "draw",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getEventDraw.matchFulfilled,
      (state, action) => {
        // console.log(action.payload)
        state.draw = action.payload.data;
      }
    );
  },
});

// Action creators are generated for each case reducer function
// export const { increment } = drawSlice.actions;

export default drawSlice.reducer;
