import { TrophyIcon } from "@heroicons/react/16/solid";
import React, { useEffect, useState } from "react";
import { useGetGroupMatchesQuery } from "../../redux/features/api/apiSlice";


const Matches = ({ groupId }) => {
  const { data: matches, isLoading, error } = useGetGroupMatchesQuery(groupId);
  console.log(matches);
  return (
    <div className="flex flex-col items-start justify-start bg-white shadow text-xs sm:text-sm p-3 mb-3 border mx-3 rounded-lg">
      {matches?.data?.map((match, i) => (
        <div key={i} className="w-full border border-gray-300 rounded mt-3">
          <div className="w-full grid grid-cols-2">
            {match?.matchPlayers
              ?.slice()
              .sort((a, b) => a?.PlayerMatch?.number - b?.PlayerMatch?.number)
              .map((player, i) => (
                <div key={i} className="p-2 flex gap-3 items-center border">
                  <div
                    className={`w-6 h-6 flex justify-center items-center text-white font-medium ${
                      player?.PlayerMatch.number === 0
                        ? "bg-blue-600"
                        : "bg-rose-600"
                    }`}
                  >
                    {player?.PlayerMatch.number === 0 ? "A" : "B"}
                  </div>
                  <div>
                    <div className="text-[10px] flex gap-3">
                      <span className="font-bold">{player?.rating},</span> {player?.homeAddress}
                    </div>
                    <div className="font-medium">
                      {player?.firstName}, {player?.lastName}
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="grid w-full grid-cols-7 h-full">
            <div className="flex flex-col items-center justify-start col-span-1 h-full">
              <div className="w-full py-2 text-center text-white bg-blue-600 border border-blue-600 h-full">
                A
              </div>
              <div className="w-full py-2 text-center text-white border border-rose-600 bg-rose-600 h-full">
                B
              </div>
            </div>

            {Array(5)
              .fill(null)
              .map((_, index) => {
                const round = match.rounds[index];
                return (
                  <div
                    className="flex flex-col items-center justify-start col-span-1 h-full"
                    key={index}
                  >
                    <div className="w-full py-2 text-center border h-full">
                      {round ? round.A : ""}
                    </div>
                    <div className="w-full py-2 text-center border h-full">
                      {round ? round.B : ""}
                    </div>
                  </div>
                );
              })}
            <div className="flex flex-col items-center justify-start col-span-1 h-full text-white font-bold">
              <div className="w-full py-2 text-center border h-full bg-blue-600">{0}</div>
              <div className="w-full py-2 text-center border h-full bg-rose-600">{0}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Matches;
