import React from 'react'
import DrawsTab from '../../components/sections/tabs/DrawsTab'

const DisplayScreen = () => {
  return (
    <div className='px-20 pb-20'>
      {/* <div>
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="grid grid-cols-5 gap-4">
        <div className="relative">
          <div className="border border-gray-400 p-4 rounded bg-white text-center">
            Quarter Final 1
          </div>
          <div className="border border-gray-400 p-4 rounded bg-white mt-4 text-center">
            Quarter Final 2
          </div>
        </div>

        <div className="relative flex items-center justify-center">
          <div className="w-8 border-r border-gray-400 h-1/2"></div>
          <div className="absolute top-0 w-full flex justify-center h-full">
            <div className="w-8 h-0.5 bg-gray-400 absolute top-1/4"></div>
            <div className="w-8 h-0.5 bg-gray-400 absolute bottom-1/4"></div>
          </div>
        </div>
        <div className="relative">
          <div className="border border-gray-400 p-4 rounded bg-white text-center">
            Semi Final 1
          </div>
          <div className="border border-gray-400 p-4 rounded bg-white mt-4 text-center">
            Semi Final 2
          </div>
        </div>

        <div className="relative flex items-center justify-center">
          <div className="w-8 border-r border-gray-400 h-1/2"></div>
          <div className="absolute top-0 w-full flex justify-center h-full">
            <div className="w-8 h-0.5 bg-gray-400 absolute top-1/4"></div>
            <div className="w-8 h-0.5 bg-gray-400 absolute bottom-1/4"></div>
          </div>
        </div>

        <div className="relative">
          <div className="border border-gray-400 p-4 rounded bg-white text-center">
            Final
          </div>
        </div>
      </div>
    </div>
      </div> */}
      <DrawsTab />
    </div>
  )
}

export default DisplayScreen