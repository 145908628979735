import React, { useState } from "react";
import { classNames } from "../../utils/classNames";
import {
  ArrowDownIcon,
  ChevronDownIcon,
  SquaresPlusIcon,
} from "@heroicons/react/16/solid";
import { useParams } from "react-router-dom";
import {
  useGetTournamentTablesQuery,
  useGroupTablesMutation,
} from "../../redux/features/api/apiSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Matches from "../commons/Matches";

// const opponents = [
//   {
//     rating: 132,
//     group: "A",
//     name: "Gorak, Daniel",
//     losses: 2,
//     wins: 1,
//     matchPlyed: 2,
//     location: "Finland",
//   },
//   {
//     rating: 2443,
//     group: "B",
//     name: "Hermann Hills",
//     losses: 0,
//     wins: 3,
//     matchPlyed: 3,
//     location: "Germany",
//   },
// ];

// const matches = [
//   {
//     eventName: "Singles",
//     group: "Group 1",
//     table: 32,
//     opponents: [
//       {
//         rating: 132,
//         group: "A",
//         name: "Gorak, Daniel",
//         losses: 2,
//         wins: 1,
//         matchPlyed: 2,
//         location: "Finland",
//         winner: true,
//       },
//       {
//         rating: 2443,
//         group: "B",
//         name: "Hermann Hills",
//         losses: 0,
//         wins: 3,
//         matchPlyed: 3,
//         location: "Germany",
//         winner: true,
//       },
//     ],
//     rounds: [
//       {
//         A: 13,
//         B: 3,
//       },
//       {
//         A: 8,
//         B: 11,
//       },
//       {
//         A: 10,
//         B: 14,
//       },
//       {
//         A: 12,
//         B: 12,
//       },
//       {
//         A: 14,
//         B: 10,
//       },
//       {
//         A: 11,
//         B: 13,
//       },
//     ],
//   },
//   {
//     eventName: "Singles PPD IK",
//     group: "Group 1",
//     table: 32,
//     opponents: [
//       {
//         rating: 132,
//         group: "A",
//         name: "Gorak, Daniel",
//         losses: 2,
//         wins: 1,
//         matchPlyed: 2,
//         location: "Finland",
//         winner: true,
//       },
//       {
//         rating: 2443,
//         group: "B",
//         name: "Hermann Hills",
//         losses: 0,
//         wins: 3,
//         matchPlyed: 3,
//         location: "Germany",
//         winner: true,
//       },
//     ],
//     rounds: [
//       {
//         A: 13,
//         B: 3,
//       },
//       {
//         A: 8,
//         B: 11,
//       },
//       {
//         A: 10,
//         B: 14,
//       },
//       {
//         A: 12,
//         B: 12,
//       },
//       {
//         A: 14,
//         B: 10,
//       },
//       {
//         A: 11,
//         B: 13,
//       },
//     ],
//   },
//   {
//     eventName: "Single Ks (kdksd)",
//     group: "Group 1",
//     table: 32,

//     opponents: [
//       {
//         rating: 132,
//         group: "A",
//         name: "Gorak, Daniel",
//         losses: 2,
//         wins: 1,
//         matchPlyed: 2,
//         location: "Finland",
//         winner: true,
//       },
//       {
//         rating: 2443,
//         group: "B",
//         name: "Hermann Hills",
//         losses: 0,
//         wins: 3,
//         matchPlyed: 3,
//         location: "Germany",
//         winner: true,
//       },
//       {
//         rating: 132,
//         group: "C",
//         name: "Gorak, Daniel",
//         losses: 2,
//         wins: 1,
//         matchPlyed: 2,
//         location: "Finland",
//         winner: true,
//       },
//       {
//         rating: 2443,
//         group: "D",
//         name: "Hermann Hills",
//         losses: 0,
//         wins: 3,
//         matchPlyed: 3,
//         location: "Germany",
//         winner: true,
//       },
//     ],
//     rounds: [
//       {
//         A: 13,
//         B: 3,
//       },
//       {
//         A: 8,
//         B: 11,
//       },
//       {
//         A: 10,
//         B: 14,
//       },
//       {
//         A: 12,
//         B: 12,
//       },
//       {
//         A: 14,
//         B: 10,
//       },
//       {
//         A: 11,
//         B: 13,
//       },
//     ],
//   },
// ];

const Stading = ({ players }) => {
  return (
    <div className="px-4 py-2 space-y-4 overflow-y-scroll divide-y max-h-[350px] border mx-3 mb-3 rounded-lg">
      {players?.map((player, i) => (
        <div className="flex flex-row items-center justify-start gap-4" key={i}>
          {/* group */}
          <div className={`w-6 h-6 text-center text-white bg-black`}>
            {i + 1}
          </div>
          <div className="flex flex-col flex-1 ">
            <div>
              <span className="text-sm font-bold">{player.player.rating}</span>
              <span>-</span>
              <span className="text-sm">{player.player.homeAddress}</span>
            </div>
            <span>
              <span className="text-sm font-bold">
                {player.player.firstName},{player?.lastName}
              </span>
            </span>
          </div>
          <div className="py-2 ">
            <div className="grid items-center justify-center grid-cols-3 grid-rows-2 gap-4 text-center">
              <span className="flex flex-col items-center justify-center text-xs text-center">
                W
              </span>
              <span className="flex flex-col items-center justify-center text-xs text-center">
                L
              </span>
              <span className="flex flex-col items-center justify-center text-xs text-center">
                P
              </span>
              <span className="flex flex-col items-center justify-center text-xs text-center">
                {player.player.wins}
              </span>
              <span className="flex flex-col items-center justify-center text-xs text-center">
                {player.player.losses}
              </span>
              <span className="flex flex-col items-center justify-center text-xs text-center">
                {player.player.matchPlyed}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const GroupCard = ({ group, admin }) => {
  let { tournamentId } = useParams();
  const [groupTables] = useGroupTablesMutation();
  const { isFetching } = useGetTournamentTablesQuery(tournamentId);
  const { tables } = useSelector((state) => state.tables) || [];
  const [editTable, setEditTable] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTable, setSelectedTable] = useState(group?.groupTables);

  const handleTables = (table) => {
    setSelectedTable((prevSelected) => {
      if (prevSelected.some((t) => t.index === table.index)) {
        return prevSelected.filter((t) => t.index !== table.index);
      } else {
        return [...prevSelected, table];
      }
    });
  };

  const handleGroupTables = async (id) => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await groupTables({ id, data: selectedTable });
      console.log(res);
      if (res?.data?.error === false) {
        toast.success(res.data.data);
        setEditTable(false);
      }
      if (res?.error?.data?.error !== undefined) {
        toast.success(res.error.data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const tabs = [
    {
      name: "stadings",
      href: "#",
      element: () => <Stading players={group?.groupPlayers} />,
    },
    {
      name: "matches",
      href: "#",
      element: () => <Matches groupId={group?.id} />,
    },
  ];

  const [activeTab, setActiveTab] = useState("stadings"); // stadings | matches
  const handleActiveTabChange = () => {
    setActiveTab(activeTab === "stadings" ? "matches" : "stadings");
  };
  return (
    <div className="flex flex-col items-start justify-start overflow-hidden bg-white rounded-md border shadow">
      <div className="flex flex-row items-center justify-between w-full px-4 py-5 text-white bg-gray-900">
        <h2 className="font-bold ">Group {group?.number + 1}</h2>
        <div className="flex items-center gap-3">
          {admin &&
            (!editTable ? (
              <div className="flex items-center gap-3">
                <button onClick={() => setEditTable(true)}>
                  <SquaresPlusIcon height={20} width={20} />
                </button>
                <div className="px-3 py-2 rounded bg-white text-black text-sm font-medium">
                  {group?.groupTables?.length < 1 ? (
                    "No Table"
                  ) : (
                    <>
                      Table{" "}
                      { group.groupTables
                        .slice() // Create a shallow copy of the array
                        .sort((a, b) => a.index - b.index) // Sort the copied array
                        .map((table, i) => (
                          <span key={i}>
                            {table.index}
                            {group?.groupTables?.length !== i + 1 && ", "}
                          </span>
                        ))}
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <button
                  className="text-lg font-medium bg-white rounded h-6 w-6 text-black"
                  onClick={() => setEditTable(false)}
                >
                  X
                </button>
                <button
                  onClick={() => setShowTable(!showTable)}
                  className="flex gap-1 items-center py-2 bg-white text-black rounded px-2 text-sm"
                >
                  {selectedTable?.length < 1
                    ? "No Table"
                    : selectedTable
                    .slice() // Create a shallow copy of the array
                    .sort((a, b) => a.index - b.index) // Sort the copied array
                    .map((table, i) => (
                        <span key={i}>
                          {table.index} {selectedTable.length !== i + 1 && ","}
                        </span>
                      ))}
                  <ChevronDownIcon height={14} width={14} />
                </button>
                {showTable && tables.length > 0 && (
                  <div className="absolute z-10 mt-[290px] space-y-2 border shadow bg-white max-h-[250px] overflow-y-scroll rounded-lg p-3">
                    {selectedTable?.length > 0 && (
                      <button
                        onClick={() => setSelectedTable([])}
                        className="text-black text-sm text-center w-full hover:bg-gray-100 py-1"
                      >
                        Reset
                      </button>
                    )}
                    {tables?.map((table, i) => {
                      const isSelected = selectedTable.some(
                        (t) => t.id === table.id
                      );
                      return (
                        <div key={i} className="text-black text-sm">
                          <button
                            onClick={() => handleTables(table)}
                            className={`flex gap-2 px-1 py-1 ${
                              isSelected ? "bg-gray-200" : "hover:bg-gray-200"
                            }`}
                          >
                            Table {table.index}{" "}
                            <span className="bg-blue-300 px-2">
                              {table?.tableNumber}
                            </span>
                          </button>
                        </div>
                      );
                    })}
                  </div>
                )}
                <button
                  onClick={() => handleGroupTables(group.id)}
                  className="bg-blue-500 px-3 py-2 text-sm rounded shadow"
                >
                  Apply
                </button>
              </div>
            ))}
        </div>
      </div>
      <div className="mt-2 ">
        <div className="">
          <nav className="flex ">
            {tabs.map((tab, i) => (
              <button
                onClick={handleActiveTabChange}
                key={i}
                className={classNames(
                  activeTab === tab.name
                    ? "text-gray-950 font-bold"
                    : "text-gray-500 font-normal",
                  "px-4 py-2 whitespace-nowrap  pb-4 text-sm font-medium capitalize"
                )}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      <div className="w-full">
        {tabs.map((tab, i) => {
          if (tab.name === activeTab) {
            return <div key={i}>{tab.element()}</div>;
          }
          return null; // Ensure a valid return for other cases
        })}
      </div>
    </div>
  );
};

export default GroupCard;
