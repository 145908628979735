import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useFinalDrawMutation,
  useGetEventDrawQuery,
  useResetDrawMutation,
} from "../../../redux/features/api/apiSlice";
import loadingImg from "../../../images/loading-image.svg";
import { useSelector } from "react-redux";
import { SquaresPlusIcon } from "@heroicons/react/16/solid";
import AddDrawForm from "../../../pages/admin/Events/AddDrawForm";
import { toast } from "react-toastify";
import GroupCard from "../../card/Group";
import RoundRobin from "../draws/RoundRobin";
import SingleElimination from "../draws/SingleElimination";

const DrawsTab = ({ event, tournament }) => {
  let { tournamentId, eventId } = useParams();
  const [finalDraw] = useFinalDrawMutation();

  const [resetDraw] = useResetDrawMutation();
  const { isFetching, refetch } = useGetEventDrawQuery(eventId);
  const { draw } = useSelector((state) => state.draws);
  const [drawForm, setDrawForm] = useState(false);
  const [deleteDraw, setDelteDraw] = useState(false);
  const [loading, setLoading] = useState(false);
  const [seeding, setSeeding] = useState(true);
  const handleDrawForm = () => {
    setDrawForm(false);
  };

  const handleDeleteDraw = () => {
    setDelteDraw(!deleteDraw);
  };

  const finaliseDraw = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await finalDraw({
        id: draw.id,
        data: { finalised: !draw.finalised, tournamentId, eventId },
      });
      if (res?.data?.error === false) {
        toast.success(res.data.data);
      }
      if (res?.error?.data?.error !== undefined) {
        toast.success(res.error.data.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleResetDraw = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await resetDraw(draw.id);
      if (res?.data?.error === false) {
        toast.success(res.data.data);
      }
      if (res?.error?.data?.error !== undefined) {
        toast.success(res.error.data.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refetch();
  }, [eventId, deleteDraw]);

  if (isFetching)
    return (
      <div className=" h-screen w-full flex justify-center items-center">
        <img className="-mt-32" src={loadingImg} alt="" />
      </div>
    );

  return (
    <>
      {drawForm ? (
        <AddDrawForm
          handleCloseForm={handleDrawForm}
          handleDeleteForm={handleDeleteDraw}
          event={event}
          tournament={tournament}
          draw={draw}
          finalised={draw?.finalised}
        />
      ) : (
        <>
          <div className="flex flex-col items-start justify-start w-full mt-8">
            {draw?.id ? (
              <div className="w-full">
                <div className="flex justify-between">
                  <div className="flex items-start gap-2 mt-5 mb-10">
                    <button onClick={() => setDrawForm(true)} className="mt-1">
                      <SquaresPlusIcon height={20} width={20} />
                    </button>

                    <div className=" ">
                      <div className="flex items-center gap-3">
                        <div className="text-lg font-bold">
                          {draw?.drawName}
                        </div>
                      </div>
                      <div className="flex items-center gap-1 mt-2">
                        <div className="text-xs font-medium text-gray-600">
                          Estemated Start: {draw?.startDate?.split("T")[0]},{" "}
                        </div>
                        <div className="text-xs font-medium text-gray-600">
                          {" "}
                          {draw?.startTime}
                        </div>
                      </div>
                      <div className="flex items-center gap-1 mt-1">
                        <div className="text-xs font-medium text-gray-600">
                          Format:{" "}
                          {draw?.drawFormat === "RR"
                            ? "Round-Robin"
                            : "Single Elimination"}
                        </div>
                      </div>
                      <div className="flex items-center gap-1 mt-1">
                        <div className="text-xs font-medium text-gray-600">
                          First to {draw?.defaultMatchFormat?.pointsPerGame}{" "}
                          (Win by {draw?.defaultMatchFormat?.deuce ? "2" : "1"}{" "}
                          ) <span className="text-sm font-black">.</span> Best
                          of {draw?.defaultMatchFormat?.gamesPerMatch}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={finaliseDraw}
                      className={` ${
                        draw?.finalised ? "bg-red-600" : "bg-blue-700"
                      } rounded shadow border text-white px-3 py-2`}
                    >
                      {draw?.finalised ? "Undo Draw" : "Finalize Draw"}
                    </button>
                  </div>
                </div>
                <div className="mb-5 flex gap-3 text-sm">
                  <button
                    onClick={handleResetDraw}
                    className={` border-blue-600 rounded shadow hover:shadow-lg border text-blue-700 px-3 py-2`}
                  >
                    Reset to Default
                  </button>
                  <button
                    onClick={() => setSeeding(!seeding)}
                    className={`   border-blue-600 rounded shadow hover:shadow-lg border text-blue-700 px-3 py-2`}
                  >
                    Seeding Rules {seeding ? "On" : "Off"}
                  </button>
                </div>
                {draw?.drawFormat === "RR" ? (
                  <>
                    {draw?.finalised ? (
                      <div className="w-full grid grid-cols-2 gap-10">
                        {draw?.groups &&
                          draw?.groups?.length &&
                          draw.groups
                            .slice() // Create a shallow copy of the array
                            .sort((a, b) => a.number - b.number) // Sort the copied array
                            .map((group, i) => (
                              <GroupCard group={group} key={i} admin={true} />
                            ))}
                      </div>
                    ) : (
                      <>
                      <RoundRobin draw={draw} seeding={seeding}/>
                      </>
                    )}{" "}
                  </>
                ) : (
                  <div>
                    {draw?.finalised ? (
                      <div></div>
                    ) : (
                      <>
                        <SingleElimination draw={draw} seeding={seeding}/>
                      </>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="w-full flex justify-center">
                <div className="text-center mt-20 text-gray-500">
                  No Draw Created Yet!
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default DrawsTab;
