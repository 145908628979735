import { useEffect, useState } from "react";
import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  useGetQueryTournamentsQuery,
  useGetTournamentTablesQuery,
} from "../../redux/features/api/apiSlice";
import { useSelector } from "react-redux";

const MatchReporter = () => {
  const [activeTab, setActiveTab] = useState("active");
  const [selected, setSelected] = useState(null);

  // Fetch tournaments based on the active tab (active/past)
  const { isFetching: tournamentFetching, refetch } =
    useGetQueryTournamentsQuery(
      new URLSearchParams({
        activeTab: activeTab,
        status: "published",
      }).toString()
    );

  const { queryTournaments } = useSelector((state) => state.tournaments);

  const { data: tables = [], isFetching: tablesFetching } =
    useGetTournamentTablesQuery(
      selected ?  selected?.id  : null,
      { skip: !selected } // Don't fetch if no tournament is selected
    );

  const handleTabChange = () => {
    setActiveTab(activeTab === "active" ? "past" : "active");
    setSelected(null); // Clear selected tournament when tab changes
  };

  useEffect(() => {
    if (!tournamentFetching) {
      refetch();
    }
  }, [activeTab]);


  if (tournamentFetching)
    return (
      <div className="flex items-center justify-center">
        <svg
          className="animate-spin h-5 w-5 text-gray-100"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
          ></path>
        </svg>
      </div>
    );

  return (
    <>
      <div className=" grid justify-center">
        <div className="text-2xl font-semibold text-center mt-10">
          Find Your Table
        </div>
        <div className="flex justify-center mt-5 gap-3">
          <Listbox
            className="w-[400px] border rounded-lg bg-gray-100"
            value={selected}
            onChange={setSelected}
          >
            <div className="relative mt-2">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                <span className="flex items-center">
                  <span className="ml-3 block truncate text-lg">
                    {selected ? selected.tournamentName : "Select Tournament"}
                  </span>
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <ChevronDownIcon
                    aria-hidden="true"
                    className="h-5 w-5 text-gray-400"
                  />
                </span>
              </Listbox.Button>

              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {queryTournaments?.length > 0 ? (
                  queryTournaments.map((tournament, i) => (
                    <Listbox.Option
                      key={i}
                      value={tournament}
                      className="group relative cursor-default border-b select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                    >
                      <div className="pl-3">
                        <span className=" block truncate font-normal group-data-[selected]:font-semibold text-lg">
                          {tournament.tournamentName}
                        </span>
                        <div className=" flex text-xs gap-1">
                          <span>{tournament.startDate}</span> -{" "}
                          <span>{tournament.endDate}</span>
                        </div>
                        <div>{tournament.location}</div>
                      </div>

                      <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                        <CheckIcon aria-hidden="true" className="h-5 w-5" />
                      </span>
                    </Listbox.Option>
                  ))
                ) : (
                  <div className="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-500">
                    No tournament found
                  </div>
                )}
              </Listbox.Options>
            </div>
          </Listbox>
          <div className="flex justify-center items-center gap-2">
            <input
              type="checkbox"
              checked={activeTab !== "active"}
              className="h-5 w-5"
              onChange={handleTabChange}
            />
            <span>Show past</span>
          </div>
        </div>
        <div className="flex gap-3 mt-5 justify-center items-center">
          {tables && tables?.data?.length >= 1 ? (
            <select className="w-full border-2 rounded text-gray-700 bg-gray-100 py-2 ">
              <option value="">Select Table</option> {/* Default option */}
              {tables?.data?.map((event, i) => (
                <option key={i} value={event.id}>
                  Table {i + 1}
                </option>
              ))}
            </select>
          ) : (
            <select
              disabled
              className="w-full border-2 rounded text-gray-700 bg-gray-100 py-2 "
            >
              <option>No tables</option>
            </select>
          )}
          <div>
            <input
              placeholder="####"
              disabled={tables?.data?.length >= 1 ? false : true}
              className="border-2 rounded bg-gray-100 py-2 text-center"
              minLength={4}
              maxLength={4}
              type="password"
            />
          </div>
          <button
            onClick={() => alert("Hi")}
            disabled={tables?.data?.length >= 1 ? false : true}
            className="px-5 py-2 bg-blue-700 hover:bg-blue-800 rounded border-2 shadow text-white font-medium"
          >
            Enter
          </button>
        </div>
      </div>
    </>
  );
};

export default MatchReporter;
